import type { FunctionComponent } from 'react';
import React from 'react';

import Responsive from 'src/components/shared/Responsive/Responsive';

import Carousel from './component';
import type { CarouselContainerProps } from './types';

import type { ResponsiveDevice } from 'src/utils/ResponsiveDetect.Utils';
import { ResponsiveDeviceEnum } from 'src/utils/ResponsiveDetect.Utils';

const CarouselContainer: FunctionComponent<CarouselContainerProps> = ({
  breakpoints,
  ...otherProps
}) => {
  if (!breakpoints) {
    return <Carousel {...otherProps} />;
  }

  let currentBreakpointProps = otherProps;
  const sortedBreakpointsMap = Object.entries(breakpoints).sort(
    ([breakpoint1], [breakpoint2]) =>
      ResponsiveDeviceEnum[breakpoint1 as ResponsiveDevice] -
      ResponsiveDeviceEnum[breakpoint2 as ResponsiveDevice],
  );

  return (
    <>
      {/* For each Responsive breakpoint, render a React component */}
      {Object.entries(ResponsiveDeviceEnum).map(([breakpoint, deviceWidth]) => {
        // Accumulate the props of matched breakpoints, in ascending order from the lowest
        currentBreakpointProps = sortedBreakpointsMap.reduce(
          (allProps, [innerBreakpoint, props]) =>
            (deviceWidth as number) >=
            ResponsiveDeviceEnum[innerBreakpoint as ResponsiveDevice]
              ? {
                  ...allProps,
                  ...props,
                }
              : allProps,
          currentBreakpointProps,
        );

        return (
          <Responsive key={breakpoint} is={breakpoint as ResponsiveDevice}>
            <Carousel {...currentBreakpointProps} />
          </Responsive>
        );
      })}
    </>
  );
};

export default CarouselContainer;
